<template>
  <b-modal
    id="modal-editor"
    hideHeaderClose
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    @shown="afterShowModal"
    @hidden="hiddenModal"
    @ok.prevent="saveData"
    @cancel="resetModal"
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder">
            {{ $t("User.Add_editor") }}
          </h2>
        </b-col>
      </b-row>
      <validation-observer ref="editor">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group :label="$t('User.Email') + ' *'" label-for="link">
                <validation-provider
                  #default="{ errors }"
                  rules="required|email"
                  :name="$t('User.Email')"
                >
                  <b-form-input
                    id="email"
                    :placeholder="$t('User.Email')"
                    v-model="user.email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BOverlay,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";

import { invite } from "@api/user";

export default {
  props: ["id"],
  name: "editor_modal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BOverlay,
    BFormTextarea,
    flatPickr,
  },
  data() {
    return {
      show: true,
      user: {
        email: "",
      },
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    afterShowModal() {
      const $this = this;
      localize("it");
      $this.show = true;
      if (this.editor_id != null) {
        geteditors(this.editor_id)
          .then((data) => {
            $this.editor = data;
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      } else {
        $this.show = false;
      }
    },
    saveData() {
      const $this = this;
      this.$refs.editor.validate().then((success) => {
        if (success) {
          $this.save();
        }
      });
      return false;
    },
    save() {
      const $this = this;
      $this.show = true;
      let formData = {
        email: $this.user.email,
      };
      invite(formData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: $this.$t("User.invitation"),
              icon: "AlertOctagonIcon",
              text: $this.$t("User.invited_success"),
              variant: "success",
            },
          });
          this.$emit("onSave");
          this.$bvModal.hide("modal-editor");
          $this.show = false;
        })
        .catch((error) => {
          console.log(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: $this.$t("User.error"),
              icon: "AlertOctagonIcon",
              text: $this.$t("User.invited_error"),
              variant: "danger",
            },
          });
          $this.show = false;
        });
    },
    resetModal() {
      this.$bvModal.hide("modal-editor");
    },
    hiddenModal() {
      this.editor = {
        time: null,
        title: "",
        description: "",
        icon: "",
        url: "",
      };
    },
  },
};
</script>
